import api from "@/api";

const getAllPaymentMethods = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.paymentMethods.getAll()
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.label };
			});
			isLoading = false;
		})
		.catch((err: any) => {
			console.log(err);
			isLoading = false;
		});
	return data;
};

const getAllVehicles = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let vehiclesData;
	await api()
		.autopack.vehicles.getAll(filter)
		.then((res: any) => {
			vehiclesData = res.data.map((t: any) => {
				return { key: t.id, value: t.mark_name + " " + t.matriculation_no };
			});
			isLoading = false;
		})
		.catch((err: any) => {
			console.log(err);
			isLoading = false;
		});
	return vehiclesData;
};

const getAllDriversData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data: any;
	await api()
		.staffs.personal.getAll(filter)
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return {
					key: t.id,
					value: t.personal_informations.name ?? "" + " " + t.personal_informations.lastname ?? "",
				};
			});
			isLoading = false;
		})
		.catch((err: any) => {
			console.log(err);
			isLoading = false;
		});
	return data;
};

const getAllTravelRoutesData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let Data;
	await api()
		.routes.getAll(filter)
		.then((res: any) => {
			Data = res.data.map((t: any) => {
				return { key: t.id, value: t.label };
			});
			isLoading = false;
		})
		.catch((err: any) => {
			console.log(err);
			isLoading = false;
		});
	isLoading = false;

	return Data;
};

const getAllEndStationData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.stations.getAll()
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.station_name };
			});
			isLoading = false;
		})
		.catch((err: any) => {
			console.log(err);
			isLoading = false;
		});
	return data;
};

const getAllVehicleTypesData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.autopack.vehiclesType.getAll()
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.label };
			});
			isLoading = true;
		})
		.catch((err: any) => {
			console.log(err);
			isLoading = false;
		});
	return data;
};

const getAllTicketTypesData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.ticketTypes.getAll(filter)
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.label };
			});
		})
		.catch((err: any) => {
			console.log(err);
		});
	isLoading = false;
	return data;
};

const getAllIdentityTypeCard = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.others.identityTypeCard.getAll()
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.label };
			});
		})
		.catch((err: any) => {
			console.log(err);
		});
	isLoading = false;
	return data;
};

const getAllEmbarkationData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.embarkations.getAll(filter)
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.label, travelDocketID: t.travel_docket.id };
			});
		})
		.catch((err: any) => {
			console.log(err);
		});
	isLoading = false;
	return data;
};

const getAllTicketRatesData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.rates.getAll(filter)
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.cost };
			});
		})
		.catch((err: any) => {
			console.log(err);
		});
	isLoading = false;
	return data;
};

const getAllTechnicalVisitStatusData = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.autopack.technicalVisitStatus.getAll(filter)
		.then((res: any) => {
			data = res.data.map((t: any) => {
				return { key: t.id, value: t.label, description: t.description };
			});
		})
		.catch((err: any) => {
			console.log(err);
		});
	isLoading = false;
	return data;
};

export {
	getAllTechnicalVisitStatusData,
	getAllPaymentMethods,
	getAllTicketRatesData,
	getAllEmbarkationData,
	getAllVehicles,
	getAllEndStationData,
	getAllTravelRoutesData,
	getAllDriversData,
	getAllVehicleTypesData,
	getAllTicketTypesData,
	getAllIdentityTypeCard,
};
