// Services
import callAPI from "../callApi";
import path from "../variables";

const Routes = {
	getAll: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/tickets/types${filter ? filter : ""}`,
			"",
			"getting all ticket types"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/tickets/types`, data, "creating new ticket types"),
	update: (data: any, typeID: number) =>
		callAPI("PUT", `${path.base}/tickets/types${typeID}`, data, "creating new ticket types"),
	delete: (data: any, typeID: number) =>
		callAPI("DEL", `${path.base}/tickets/types${typeID}`, data, "creating new ticket types"),
};

export default Routes;
