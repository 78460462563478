// Services
import callAPI from "../callApi";
import path from "../variables";

const PassengersBaggages = {
	getAll: (filter?: any) =>
		callAPI(
			"GET",
			`${path.base}/tickets/passengers/baggages${filter ? filter : ""}`,
			"",
			"getting all Passengers Baggages"
		),
	create: (data: any) =>
		callAPI(
			"POST",
			`${path.base}/tickets/passengers/baggages`,
			data,
			"register new Passenger Baggages"
		),
};

export default PassengersBaggages;
