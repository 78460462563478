// Services
import callAPI from "../callApi";
import path from "../variables";

const Embarkations = {
	getAll: (filter?: any) =>
		callAPI(
			"GET",
			`${path.base}/planning/embarkations${filter ? filter : ""}`,
			"",
			"getting all embakations"
		),
	detail: (id: number) =>
		callAPI("GET", `${path.base}/planning/embarkations/${id}`, "", "getting a specific embakation"),
	create: (data: any) =>
		callAPI("POST", `${path.base}/planning/embarkations`, data, "creating embakations"),
	update: (data: any, id: number) =>
		callAPI("PUT", `${path.base}/planning/embarkations/${id}`, data, "updating embarkation"),
};

export default Embarkations;
