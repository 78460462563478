<template>
	<div
		class="bg-no-repeat app-background"
		style="
			background-image: url('/media/images/effect-ondark.png'), linear-gradient(180deg, #2d313e, #030f13);
			background-position: right bottom;
		"
	>
		<slot></slot>
	</div>
</template>
