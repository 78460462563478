// Services
import callAPI from "../callApi";
import path from "../variables";

const Routes = {
	getAll: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/tickets/rates${filter ? filter : ""}`,
			"",
			"getting all tickets rates"
		),
	create: (data: any) => callAPI("POST", `${path.base}/tickets/rates`, data, "creating new rate"),
};

export default Routes;
