<template>
	<div class="flex flex-row items-center w-full app-auth-layout">
		<div class="">
			<img
				class="object-cover w-auto min-h-screen"
				src="/media/images/BackgroundImage.png"
				alt="Illustration Image"
			/>
		</div>
		<div class="flex flex-col w-full p-4">
			<div style="height: calc(100vh - 5rem)">
				<slot></slot>
			</div>
			<AppAuthFooter />
		</div>
	</div>
</template>
