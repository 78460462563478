<template>
	<div class="relative top-0 left-0 w-full h-auto skeleton z-75">
		<!-- <div class="absolute top-0 left-0 w-full skeleton-line h-96"></div> -->
		<slot></slot>
	</div>
</template>

<style lang="scss" scoped>
	@keyframes line-animation {
		from {
			transform: translateY(-15vw);
		}
		to {
			transform: translateY(115vw);
		}
	}
	.skeleton-line {
		background: rgb(71, 12, 54);
		background: linear-gradient(
			180deg,
			rgba(242, 242, 242, 0) 10%,
			rgba(242, 242, 242, 0.25) 50%,
			rgb(80, 6, 65, 0.5) 50%,
			rgb(132, 12, 108) 20%,
			rgba(242, 242, 242, 0.25) 5%,
			rgba(242, 242, 242, 0) 50%
		);
		animation-name: line-animation;
		animation-duration: 2s;
		animation-timing-function: cubic-bezier(0.18, 0, 0.4, 1);
		animation-iteration-count: infinite;
	}

	:deep(.p-skeleton) {
		background-color: #08171d;
	}
</style>
