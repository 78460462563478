const path = "./models";
const requireModel = require.context(
	// The relative path of the models folder
	"./models",
	// Whether or not to look in subfolders
	true,
	// The regular expression used to match models filenames
	/[a-z]\w+\.(ts)$/
);

export default () => {
	const api: any = {};
	requireModel.keys().forEach((fileName: any) => {
		// Get model name
		const modelName = fileName
			.split("/")
			.pop()
			?.replace(/\.\w+$/, "") as string;
		// Get the current model object
		const modelConfig = requireModel(fileName);
		api[modelName] = modelConfig.default;
	});
	return api;
};
