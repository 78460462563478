import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import menu from "@/router/menu";

/**
 * Variables
 */

const navigations: any = ref(menu);

export default { navigations };
