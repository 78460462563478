import Driver from "driver.js";

let driver: any = null;

const init = (option?: any) => {
	driver = new Driver({
		className: "!text-white", // className to wrap driver.js popover
		animate: true, // Whether to animate or not
		opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
		padding: 10, // Distance of element from around the edges
		allowClose: true, // Whether the click on overlay should close or not
		overlayClickNext: false, // Whether the click on overlay should move next
		doneBtnText: "Terminé", // Text on the final button
		closeBtnText: "Fermer", // Text on the close button for this step
		stageBackground: "#282838", // Background color for the staged behind highlighted element
		nextBtnText: "Suivant", // Next button text for this step
		prevBtnText: "Précédent", // Previous button text for this step
		showButtons: true, // Do not show control buttons in footer
		onDeselected: (Element) => {
			console.log("Element: ", Element);
		},
	});
};

export default {
	runIntroduction: async () => {
		init();

		// Define the steps for introduction
		driver.defineSteps([
			{
				element: "#mainMenu",
				popover: {
					className: "!bg-primary !text-white",
					title: "Menu Principal",
					description:
						"Ce bloc contient toutes les fonctionnalités de votre logiciel dont vous aurez besoin.",
					position: "right",
				},
			},
			{
				element: "#secondMenu",
				popover: {
					className: "!bg-primary !text-white",
					title: "Menu Secondaire",
					description:
						"Ce bloc contient toutes les fonctionnalités de votre logiciel dont vous aurez besoin.",
					position: "right",
				},
			},
			{
				element: "#switchTag",
				popover: {
					className: "!bg-primary !text-white",
					title: "Sélecteur de succursale",
					description: "C'est ici que vous passerez d'une de vos agence à l'autre",
					position: "bottom",
				},
			},
			{
				element: "#dashboard",
				popover: {
					className: "!bg-primary !text-white",
					title: "Tableau de bord",
					description: "",
					position: "left",
				},
			},
		]);

		// driver.value.highlight({
		// 	element: '#menu',
		// 	popover: {
		// 		title: 'Title for the Popover',
		// 		description: 'Description for it',
		// 		position: "right",
		// 	}
		// });

		// Start the introduction
		driver.start();
	},

	runNewButtonOperation: async (currentStep: any) => {
		init();

		driver.highlight({
			element: "#newButtonOperation",
			popover: {
				className: "!bg-primary !text-white",
				title: "Aide à la navigation",
				description: "Cliquer ici pour effectuer une nouvelle opération",
				position: "left",
			},
		});

		// autoClose Popover
		setTimeout(() => {
			driver.reset();
		}, 3000);
	},

	runNewButtonStation: async (currentStep: any) => {
		init();

		driver.highlight({
			element: "#newAgencyButton",
			popover: {
				className: "!bg-primary !text-white",
				title: "Aide à la navigation",
				description: "Cliquer ici pour créer votre première succursale",
				position: "right",
			},
		});

		// autoClose Popover
		setTimeout(() => {
			driver.reset();
		}, 3000);
	},
};
