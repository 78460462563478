<template>
	<div class="flex flex-col space-y-8">
		<!-- Section 1 -->
		<div class="flex flex-col w-full space-y-3">
			<div class="flex flex-row items-center space-x-3 mb-2">
				<p class="text-2xl font-bold text-accent bg-[#172127] rounded-md w-1/3 h-6"></p>
				<p class="text-sm text-gray-400 italic w-[50px] h-6 bg-[#172127] rounded-md"></p>
			</div>

			<p class="w-full text-gray-400 h-4 bg-[#172127] rounded-md"></p>
			<p class="w-full text-gray-400 h-4 bg-[#172127] rounded-md"></p>
			<p class="w-full text-gray-400 h-4 bg-[#172127] rounded-md"></p>
			<p class="w-full text-gray-400 h-4 bg-[#172127] rounded-md"></p>
		</div>

		<!-- Section 2 -->
		<div class="flex flex-row space-x-4">
			<div class="grid w-full grid-cols-3 gap-4 p-5 pt-2 bg-[#172127] rounded-md h-[200px]"></div>

			<!-- <ImageView src="/media/images/airplane.jpg" css="h-[200px] rounded-xl w-1/2" /> -->
			<ImageView src="/media/images/thumbnail-3-img.svg" css="h-[200px] rounded-xl w-1/2" />
		</div>
	</div>
</template>

<style scoped lang="scss">
	.custum-bg-opacity-40 {
		background-color: rgba(112, 11, 102, 0.4);
	}
</style>
