// Services
import callAPI from "../callApi";
import path from "../variables";

const User = {
	getAll: () => callAPI("GET", `${path.base}/users`, "", "getting all users"),
	getUserInfosWithID: (id: any) =>
		callAPI("GET", `${path.base}/users/${id}`, "", "getting user infos with ID"),
	getUserInfos: () => callAPI("GET", `${path.base}/user/infos`, "", "retrieving user details"),
	editUserInfos: (id: string, data: any) =>
		callAPI("PUT", `${path.base}/users/${id}`, data, "updating user infos"),
	editPassword: (data: any) =>
		callAPI("POST", `${path.base}/user/password`, data, "updating password"),
};

export default User;
