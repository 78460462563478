// Services
import callAPI from "../callApi";
import path from "../variables";

const Routes = {
	getAll: (filter: string) =>
		callAPI(
			"GET",
			`${path.base}/others/contracttypes${filter ? filter : ""}`,
			"",
			"getting all contract types"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/others/contracttypes`, data, "creating contract type"),
	update: (data: any, id: number) =>
		callAPI("PUT", `${path.base}/others/contracttypes/${id}`, data, "updating contract type"),
	delete: (data: any, id: number) =>
		callAPI("DEL", `${path.base}/others/contracttypes/${id}`, data, "delete contract type"),
};

export default Routes;
