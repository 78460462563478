/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-empty-function */
// import "./registerServiceWorker";

import { createApp } from "vue";
import { createHead } from "@vueuse/head";

import App from "./App.vue";
import router from "./router";

// Loading all components
import globalComponent from "./components";
import layoutComponent from "./layouts";

// Libraries
import numeral from "numeral";

/**
 * Importing external libraries allow to compile them in our bundle
 * How files are interpreted is defined by ther extension.
 */

// Main Style
import "./assets/scss/main.scss";

// Driver.js
import "driver.js/dist/driver.min.css";

import "nprogress/nprogress.css";
import PrimeVue from "primevue/config";

// Primevue style
import "primeflex/primeflex.css";
import "./assets/css/ds-primevue.css";
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";

// Primevue Components
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import DataTable from "primevue/datatable";
import ContextMenu from "primevue/contextmenu";
import Calendar from "primevue/calendar";
import AutoComplete from "primevue/autocomplete";
import Skeleton from "primevue/skeleton";

// Notification style
import "notyf/notyf.min.css";

// API
import * as api from "./api";

// Services
import * as services from "./services";

// Change POST content type
services.axios.defaults.headers.post["Content-Type"] = "application/json, text/plain, */*";

const app_env = process.env.VUE_APP_ENV;

if (app_env == "production") {
	console.log = () => {};
	console.warn = () => {};
	console.info = () => {};
	console.error = () => {};
}

// Activate session manager
// services.sessionManager.setTabSession();
// services.sessionManager.setTabSessionListener();

// Connect user if token exist and is valid
services.auth.loginWithToken();

// Activate content protection from being copied or modified
// services.contentProtector();

// Now we can start our vue app
const app = createApp(App);
const head = createHead();

globalComponent.register(app);
layoutComponent.register(app);

// Filters
app.config.globalProperties.$filters = {
	moneyFormat(value: number) {
		if (value == 0) {
			return value;
		} else {
			return numeral(value).format("0.0a");
		}
	},
};

app.use(router);
app.use(head);
app.use(PrimeVue);

// Primevu components
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("DataTable", DataTable);
app.component("ContextMenu", ContextMenu);
app.component("Calendar", Calendar);
app.component("AutoComplete", AutoComplete);
app.component("Skeleton", Skeleton);

app.mixin({
	computed: {
		$api() {
			return {
				...api,
			};
		},
	},
});

app.mount("#app");
