// Services
import callAPI from "../callApi";
import path from "../variables";

const ResponsabilityFunctions = {
	getAll: (filter: string) =>
		callAPI(
			"GET",
			`${path.base}/others/responsabilityfunctions${filter ? filter : ""}`,
			"",
			"getting all Responsability Functions"
		),
	create: (data: any) =>
		callAPI(
			"POST",
			`${path.base}/others/responsabilityfunctions`,
			data,
			"creating Responsability Function"
		),
	update: (data: any, id: number) =>
		callAPI(
			"PUT",
			`${path.base}/others/responsabilityfunctions${id}`,
			data,
			"updating Responsability Function"
		),
	delete: (data: any, id: number) =>
		callAPI(
			"DEL",
			`${path.base}/others/responsabilityfunctions`,
			"",
			"deleting Responsability Function"
		),
};

export default ResponsabilityFunctions;
