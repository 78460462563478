import api from "@/api";

const getAll = async (filter?: any, page?: any, isLoading = false) => {
	isLoading = true;
	let data;
	await api()
		.ticketTypes.getAll(filter == null ? "" : filter)
		.then((res: any) => {
			data = res.data;
		})
		.catch((err: any) => {
			console.log(err);
		});
	isLoading = false;
	return data;
};

export default {
	getAllOptions: async (filter?: any, page?: any, isLoading = false) => {
		isLoading = true;
		const res: any = await getAll(null, null, isLoading);

		if (res)
			return res.data.map((t: any) => {
				return { key: t.id, value: t.label };
			});
		else return [];
	},

	getAll: (filter?: any, page?: any, isLoading = false) => getAll(filter, page, isLoading),
};
