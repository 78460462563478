export const themeColors = {
	primary: "#41b883",
	primaryMedium: "#d4b3ff",
	primary800: "#177f4c",
	primaryLight: "#b8ccff",
	secondary: "#ff227d",
	accent: "#797bf2",
	success: "#06d6a0",
	info: "#039BE5",
	warning: "#faae42",
	danger: "#FF7273",
	purple: "#8269B2",
	blue: "#37C3FF",
	green: "#93E088",
	yellow: "#FFD66E",
	PrimaryYellow: "#f9c12c",
	orange: "#FFA981",
	lightText: "#a2a5b9",
	fadeGrey: "#ededed",
};
