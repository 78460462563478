<template>
	<div class="flex flex-col space-y-2">
		<CardWidget
			css="!h-[100px] pr-4 mb-4 flex flex-row w-full"
			v-for="(item, index) in Array(4)"
			:key="index"
		>
			<div
				:class="{
					' h-full mr-4 opacity-50': true,
				}"
			></div>
			<div class="flex flex-col justify-center w-full space-y-6">
				<div class="flex flex-row justify-between">
					<div class="flex flex-row items-center space-x-8">
						<p
							class="w-12 h-2 text-2xl font-semibold text-gray-500 bg-gray-500 opacity-50 rounded-xl"
						></p>
						<p
							:class="{
								'text-[#F1AC5E] w-12 h-2 bg-gray-500 opacity-50 rounded-xl': true,
							}"
						></p>
					</div>
					<Icon src="/media/icons/more-actions-icons.svg" css="h-6 w-6 opacity-50" />
				</div>
				<div class="flex flex-row justify-between pr-2 text-sm text-gray-400 items-centerr">
					<Skeleton width="48px" height="8px" class="opacity-50"></Skeleton>
					<Skeleton width="48px" height="8px" class="opacity-50"></Skeleton>
					<Skeleton width="48px" height="8px" class="opacity-50"></Skeleton>
					<Skeleton width="48px" height="8px" class="opacity-50"></Skeleton>
					<Skeleton width="48px" height="8px" class="opacity-50"></Skeleton>
					<Skeleton width="48px" height="8px" class="opacity-50"></Skeleton>
				</div>
			</div>
		</CardWidget>
	</div>
</template>

<style scoped lang="scss">
	// ..
</style>
