<template>
	<div class="w-full">
		<div class="w-full">
			<!-- <div
				class="w-full h-12 mb-2 bg-primary-o10"
				v-for="(item, index) in new Array(50)"
				:key="index"
			></div> -->
			<Skeleton
				class="my-2"
				shape="rounded"
				borderRadius="12px"
				height="32px"
				width="100%"
				v-for="(item, index) in new Array(50)"
				:key="index"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	:deep(.p-skeleton) {
		background-color: #08171d;
		border-radius: 2px;
	}
</style>
