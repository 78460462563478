<template>
	<div class="relative">
		<!-- Blur Background -->
		<div class="absolute z-10 w-full h-full blur-md opacity-80 bg-primary"></div>
	</div>
</template>

<style lang="scss" scoped>
	// ..
</style>
