import { watchEffect } from "vue";
import api from "@/api";
import router from "@/router";
import { axios } from "@/services";
import {
	cookies,
	authToken,
	saveToken,
	authStatus,
	isLoggedIn,
	updateAuthStatus,
	activateTokenRefreshTimer,
} from "@/state/api/authState";
import { updateUserInfos, resetUserInfos } from "@/state/api/userState";

import { detectPlatform, validToken, getExpDiff } from "@/utils";

const platform = detectPlatform();

const getUserInfos = () => {
	// We're retrieving the user's info
	api()
		.user.getUserInfos()
		.then((userInfos: any) => {
			// Save user infos
			updateUserInfos(userInfos.data);

			// Change auth status to signal user connected
			updateAuthStatus("connected");
		})
		.catch((err: any) => {
			console.error("%c Warning ", "color: #FFC400;", "Auto login Error :", err.data.message);
			updateAuthStatus("error");
		});
};

const getNewToken = (token: any) => {
	// Change auth status to token is refreshing
	updateAuthStatus("refreshing");
	// Launch API call
	api()
		.auth.refreshToken({ refresh_token: token.key_res })
		.then((response: any) => {
			updateAuthStatus("refreshed");

			saveToken(response).then(() => {
				// We get the user's information
				getUserInfos();
			});
		})
		.catch((err: any) => {
			router.push({
				name: "auth-logout",
				params: { action: "force" },
			});
		});
};

const loginWithToken = () => {
	// detect curent route
	const currentRoute = window.location.href.split("auth/").pop();
	// Check if it is different from logout
	if (currentRoute != "logout" && currentRoute != "logout/force") {
		const token = validToken();

		// Change auth status to loading
		updateAuthStatus("loading");

		// Execute actions according to the token status
		switch (token.status) {
			case "valid":
				// Authenticate with token
				axios.defaults.headers.common.Authorization = `Bearer ${token.key}`;
				activateTokenRefreshTimer(token);

				// We get the user's information
				getUserInfos();
				break;

			case "invalid":
				// Set auth to initial status
				updateAuthStatus("initial");
				break;

			case "empty":
				// Set auth to initial status
				updateAuthStatus("initial");
				break;

			default:
				break;
		}
	}
};

export default { loginWithToken };
