// Vue core & library
import { ref } from "vue";
// Router
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
// Services
import routing from "./routing";

/**
 * Variables
 */
const navigationsCallback = ref<any>({
	goBack: null,
	goNext: null,
	reloadPage: null,
});

const activeWorkspace = ref<any>(null);
const activePage = ref<any>(null);
const subNavigations = ref([]);
const currentNavigation: any = ref([]);

function findActive(route: RouteLocationNormalizedLoaded) {
	if (route.matched.length > 0 && route.matched[0].name != "auth") {
		currentNavigation.value = routing.navigations.value.filter((navigation: any) =>
			navigation.route.startsWith(route.matched[1]?.name)
		);
		console.log("currentNavigation.value: ", currentNavigation.value);
		if (currentNavigation.value.length > 0) {
			subNavigations.value = currentNavigation.value[0].elements;
			activeWorkspace.value = currentNavigation.value[0].name;
		}
		console.log("routing.navigations: ", routing.navigations);
	} else {
		activeWorkspace.value = null;
	}
}

const goBack = (router: Router) => {
	if (navigationsCallback.value.goBack != null) {
		const callback: any = navigationsCallback.value.goBack;
		callback();
	} else {
		router.go(-1);
	}
};

const goNext = (router: Router) => {
	if (navigationsCallback.value.goNext != null) {
		const callback: any = navigationsCallback.value.goNext;
		callback();
	} else {
		router.go(1);
	}
};

const reloadPage = (router: Router) => {
	if (navigationsCallback.value.reloadPage != null) {
		const callback: any = navigationsCallback.value.reloadPage;
		callback();
	} else {
		router.push({ name: "workspace-emtpy" }).then(() => {
			router.go(-1);
		});
	}
};

export default {
	findActive,
	goBack,
	goNext,
	reloadPage,
	activeWorkspace,
	subNavigations,
	activePage,
};
