<template>
	<div class="flex flex-col space-y-2">
		<CardWidget
			css="!h-[150px] pl-0 pr-10 py-0 flex-row"
			v-for="(item, index) in Array(4)"
			:key="index"
		>
			<div
				class="h-full w-[300px] bg-no-repeat bg-center bg-cover rounded-xl"
				style="background-image: url(/media/icons/thumbnail-2-img.svg)"
			></div>
			<div class="flex flex-col justify-between py-4 pl-6 pr-4 w-full">
				<div class="flex flex-col space-y-3">
					<!-- <p class="h-2 w-24 bg-gray-500 rounded-xl"></p> -->
					<Skeleton width="96px" height="8px"></Skeleton>
					<Skeleton width="96px" height="12px"></Skeleton>
					<!-- <p class="opacity-50 h-2 w-12 bg-gray-500 rounded-xl"></p> -->
				</div>
				<div class="flex flex-row justify-between space-x-8">
					<div class="flex flex-row space-x-12">
						<div class="flex flex-row space-x-2">
							<Skeleton width="96px" height="12px"></Skeleton>
						</div>
						<div class="flex flex-row space-x-2">
							<!-- <span class="opacity-50 h-3 w-12 bg-gray-500 rounded-xl"></span> -->
							<Skeleton width="96px" height="12px"></Skeleton>
						</div>
						<div class="flex flex-row space-x-2">
							<!-- <span class="opacity-50 h-3 w-12 bg-gray-500 rounded-xl"></span> -->
							<Skeleton width="96px" height="12px"></Skeleton>
						</div>
						<div class="flex flex-row space-x-2">
							<!-- <span class="opacity-50 h-3 w-12 bg-gray-500 rounded-xl"></span> -->
							<Skeleton width="96px" height="12px"></Skeleton>
						</div>
					</div>
					<div class="flex flex-row space-x-2 items-center">
						<!-- <span class="opacity-50 h-3 w-24 bg-gray-500 rounded-xl"> </span> -->
						<Skeleton width="96px" height="12px"></Skeleton>
					</div>
				</div>
			</div>
		</CardWidget>
	</div>
</template>

<style scoped lang="scss">
	// ..
</style>
