// Services
import callAPI from "../callApi";
import path from "../variables";

const RoutesAndRates = {
	getAll: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/tickets/rates${filter ? filter : ""}`,
			"",
			"getting all Routes And Rates"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/tickets/rates`, data, "creating new Rates Ticket "),
};

export default RoutesAndRates;
