<template>
	<div class="text-white skeleton-loader">
		<div class="grid grid-cols-4 gap-4">
			<div
				class="flex flex-col bg-[#0D181C] rounded-xl cursor-pointer h-[250px]"
				v-for="(item, index) in new Array(6)"
				:key="index"
			>
				<div class="h-[200px] rounded-t-xl bg-[#161e21]"></div>
				<div class="flex items-center justify-between p-4">
					<div class="flex flex-col w-full pr-8 space-y-1">
						<span class="flex items-center w-full space-x-2">
							<Skeleton shape="rounded" height="8px" />
							<Skeleton shape="rounded" height="8px" />
						</span>
						<span class="flex flex-col w-full space-y-1 text-sm text-gray-400">
							<Skeleton width="100%" height="8px"></Skeleton>
							<Skeleton width="100%" height="8px"></Skeleton>
						</span>
					</div>
					<Skeleton shape="circle" size="32px"></Skeleton>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.custum-bg-opacity-40 {
		background-color: rgba(112, 11, 102, 0.4);
	}
</style>
