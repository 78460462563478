<template>
	<div class="flex flex-row space-x-2 items-center">
		<Icon src="/media/icons/search-icon.svg" css="w-6 h-6" class="opacity-50" />
		<input
			class="rounded-fulll bg-transparent outline-none focus:outline-none autofill:bg-transparent"
			type="text"
			placeholder="Tape to search..."
		/>
	</div>
</template>
