import useNotyf from "@/composable/useNotyf";

const notification = useNotyf();

interface Validator {
	// Validation Settings
	value: any;
	// eslint-disable-next-line @typescript-eslint/ban-types
	validator: RegExp | Function;
	msg: {
		error: string;
		success: string;
		notify: boolean;
	};
	// Parameters
	state: boolean;
	message: string;
}

/**
 *
 * @param validations
 * 		@param name : The name of your input or a description about the validation you want to make
 */

function validate(name: Validator): boolean {
	if (typeof name.validator == "function") {
		if (name.validator(name.value) == true) {
			name.message = name.msg.success;
			name.state = true;
			if (name.msg.notify) notification.success(name.message);
			return true;
		} else {
			name.message = name.msg.error;
			name.state = false;
			if (name.msg.notify) notification.error(name.message);
			return false;
		}
	} else {
		if (name.validator.test(name.value)) {
			name.message = name.msg.success;
			name.state = true;
			if (name.msg.notify) notification.success(name.message);
			return true;
		} else {
			name.message = name.msg.error;
			name.state = false;
			if (name.msg.notify) notification.error(name.message);
			return false;
		}
	}
}

function validateAll(validations: any): boolean {
	let state = true;
	for (const index in validations) {
		if (!validate(validations[index])) state = false;
	}
	return state;
}

export { validate, validateAll };
