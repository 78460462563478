// Services
import callAPI from "../callApi";
import path from "../variables";

const PaymentMethods = {
	getAll: (filter: string) =>
		callAPI(
			"GET",
			`${path.base}/paymentmethods${filter ? filter : ""}`,
			"",
			"getting all payment methods"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/paymentmethods`, data, "creating payment method"),
	update: (data: any, paymentID: number) =>
		callAPI("PUT", `${path.base}/paymentmethods${paymentID}`, "", "updating payment methods"),
	delete: (data: any, paymentID: number) =>
		callAPI("DEL", `${path.base}/paymentmethods${paymentID}`, "", "getting all payment methods"),
};

export default PaymentMethods;
