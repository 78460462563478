// Services
import callAPI from "../callApi";
import path from "../variables";

const Routes = {
	getAll: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/planning/travelroutes${filter ? filter : ""}`,
			"",
			"getting all routes"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/planning/travelroutes`, data, "creating new route"),
};

export default Routes;
